import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { css } from '@emotion/core';
import * as React from 'react';
export default {
  FontAwesomeIcon,
  faFacebookF,
  faTwitter,
  faInstagram,
  css,
  React
};