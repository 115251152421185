// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-default-tsx": () => import("./../../../src/components/pages/default.tsx" /* webpackChunkName: "component---src-components-pages-default-tsx" */),
  "component---src-components-pages-post-tsx": () => import("./../../../src/components/pages/post.tsx" /* webpackChunkName: "component---src-components-pages-post-tsx" */),
  "component---src-components-pages-posts-tsx": () => import("./../../../src/components/pages/posts.tsx" /* webpackChunkName: "component---src-components-pages-posts-tsx" */),
  "component---src-components-pages-shareable-tsx": () => import("./../../../src/components/pages/shareable.tsx" /* webpackChunkName: "component---src-components-pages-shareable-tsx" */),
  "component---src-components-pages-tag-tsx": () => import("./../../../src/components/pages/tag.tsx" /* webpackChunkName: "component---src-components-pages-tag-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shareables-tsx": () => import("./../../../src/pages/shareables.tsx" /* webpackChunkName: "component---src-pages-shareables-tsx" */)
}

